/* Privacy styles */
.privacy-container {
    font-family: Arial, Helvetica, sans-serif;
    padding: 4rem;
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
}

.privacy-container p {
    font-family: Arial, Helvetica, sans-serif;

    padding-bottom: 1rem;
}
.privacy-container h1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding-bottom: 4rem;
}

.privacy-container h2, .privacy-container h3 {
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 1rem;
}

@media (max-width: 900px) {
    .privacy-container {
        padding: 2rem;
    }

    .privacy-container h1 {
        padding-bottom: 2rem;
    }
}