/* Contact page Styles */
.contact-page {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.contact-page .contact-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.contact-page .contact-title h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.contact-page .contact-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#064D94, #0A66C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .contact-page .contact-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#3D9EFF, #208fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-page .contact-title p {
    max-width: 450px;
}

.contact-page .contact-container {
    padding: 4rem;
    border-radius: 8px;
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.contact-page .contact-container .contact-form{
    flex: .85;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.contact-page .contact-container .contact-form .contact-form-title h2{
    font-size: 1.75rem;
}

.contact-page .contact-container .contact-form .contact-form-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.contact-page .contact-container .contact-form .contact-form-content .names-container{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.contact-page .contact-container .contact-form .contact-form-content .names-container input{
    flex: 1;
}

.contact-page .contact-container .contact-form .contact-form-content input,
.contact-page .contact-container .contact-form .contact-form-content textarea,
.contact-page .contact-container .contact-form .contact-form-content select
{
    padding: 1rem;
    border: 1px solid #dedfdf1e;
    border-radius: 5px;
    background-color: #ffffff06;
    transition: all .2s ease-in-out;
    width: 100%;
}

.contact-page .contact-container .contact-form .contact-form-content input:hover,
.contact-page .contact-container .contact-form .contact-form-content textarea:hover,
.contact-page .contact-container .contact-form .contact-form-content select:hover,
.contact-page .contact-container .contact-form .contact-form-content input:active,
.contact-page .contact-container .contact-form .contact-form-content textarea:active {
    border: 1px solid #0A66C2;
}

.contact-page .contact-container .contact-form .contact-form-content input:focus,
.contact-page .contact-container .contact-form .contact-form-content textarea:focus {
    border: 2px solid #0A66C2;
}

.dark-mode .contact-page .contact-container .contact-form .contact-form-content input:hover,
.dark-mode .contact-page .contact-container .contact-form .contact-form-content textarea:hover,
.dark-mode .contact-page .contact-container .contact-form .contact-form-content select:hover,
.dark-mode .contact-page .contact-container .contact-form .contact-form-content input:active,
.dark-mode .contact-page .contact-container .contact-form .contact-form-content textarea:active {
    border: 1px solid #3D9EFF;
}

.dark-mode .contact-page .contact-container .contact-form .contact-form-content input:focus,
.dark-mode .contact-page .contact-container .contact-form .contact-form-content textarea:focus {
    border: 2px solid #3D9EFF;
}

.contact-page .contact-container .contact-form .contact-form-content option {
    background-color: #dedfdf;
    color: #1B1F23;
    padding: 8px;
  }

.contact-page .contact-container .contact-form .contact-form-content textarea {
    resize: vertical;
}

.contact-page .contact-container .contact-form .contact-form-content button{
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #0A66C2;
    color: #fff;
    transition: all .2s ease-in-out;
}

.dark-mode .contact-page .contact-container .contact-form .contact-form-content button{
    background-color: #dedfdf;
    color: #1B1F23;
}

.contact-page .contact-container .contact-form .contact-form-content button:hover{
    background-color: #064D94;
}

.dark-mode .contact-page .contact-container .contact-form .contact-form-content button:hover{
    background-color: #3D9EFF;
}

.contact-page .contact-container .contact-form .contact-form-content button:active{
    transform: scale(.95);
}

.contact-page .contact-container .contact-social-title h2{
    padding-bottom: .5rem;
}

.contact-page .contact-container .contact-social-container { 
    padding: 3rem;
    background-color: #0A66C2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    flex: .75;
}

.dark-mode .contact-page .contact-container .contact-social-container { 
    background-color: #3D9EFF;
    color: #1B1F23;
}

.contact-page .contact-container .contact-social-container .contact-social {
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: flex-start;
}

.contact-page .contact-container .contact-social-container .contact-social .email {
    font-weight: bold;
    transition: all .2s ease-in-out;
}

.contact-page .contact-container .contact-social-container .contact-social .email:hover {
color: #fff;
text-decoration: underline;
}


.contact-page .contact-container .contact-social-container .contact-social .icon{ 
    font-size: 20px;
    transition: all .2s ease-in-out;
}

.contact-page .contact-container .contact-social-container .contact-social .icon:hover { 
    transform: scale(1.2);
    color: #ffffff;
}

.contact-page .contact-container .contact-social-container .contact-social .icon:active{ 
    transform: scale(.9);
}

@media (max-width: 900px) {
    .contact-page {
        padding: 2rem 0;
        gap: 2rem;
    }

    .contact-page .contact-title{
        padding: 0 2rem;
    }

    .contact-page .contact-container{
        padding: 2rem;
        border-radius: 0;
        gap: 2rem;
        flex-direction: column;
    }

    .contact-page .contact-container .contact-social-container{
        padding: 2rem;
    }
}

@media (max-width: 670px) {
    .contact-page .contact-title h1 { 
        font-size: 2.3rem;
    }
}

@media (max-width: 484px) {

    .contact-page .contact-title h1 { 
        font-size: 2rem;
    }
}