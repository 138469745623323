*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: "Inter", Arial, Helvetica, serif;
    /* font-family: "Arya", sans-serif; */
    line-height: 1.3;
}

body .dark-mode {
    background-color: #1B1F23;
    color: #f3f3f3;
}

a {
    text-decoration: none;
    color: inherit;
}

input, textarea, select {
    background: none;
    border: none;
    outline: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

select {
    cursor: pointer;
}

button{
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.btn {
    background-color: #0A66C2;
    font-size: 1rem;
    padding: .75rem 3rem;
    border-radius: 5px;
    min-width: max-content;
    transition: all .2s ease-in-out;
    text-align: center;
    font-weight: 600;
}


.dark-mode .btn {
    background-color: #3D9EFF;
    color: #1B1F23;
}

.btn:hover {
    background-color: #0856a4;
    transform: scale(1.05);
}

.dark-mode .btn:hover {
    background-color: #208fff;
}

.btn:active, .dark-mode .btn:active {
    transform: scale(.95);
}

.btn:disabled, .dark-mode .btn:disabled {
    cursor: not-allowed;
    transform: none;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.color-blue{
    color: #0A66C2;
}

.underline {
    text-decoration: underline;
}

.dark-mode .color-blue {
    color: #3D9EFF;
}

.logout-btn {
    background-color: rgb(193, 65, 65);
    font-size: 1rem;
    padding: .5rem 1rem;
    border-radius: 5px;
}
    
/* Navbar styles */
.navbar-inner {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1.5rem 4rem;
    /* padding-bottom: 0; */
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    text-align: center;
    /* position: sticky;
    top: 0;
    background: #1B1F23;
    z-index: 100;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
     */
}

.navbar .nav-links {
    display: flex;
    gap: 1rem;
    font-size: 1rem;
    font-weight: normal;
    align-items: center;
}

.navbar .nav-links .login-btn {
    transition: all .2s ease-in-out;
}

.navbar .nav-links .login-btn:hover { 
    color: #0A66C2;
}

.dark-mode .navbar .nav-links .login-btn:hover { 
    color: #3D9EFF;
}

.navbar .nav-links .signup-btn {
    background-color: #0A66C2;
    color: #dedfdf;
    padding: .5rem 1rem;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}

.dark-mode .navbar .nav-links .signup-btn {
    background-color: #3D9EFF;
    color: #1B1F23;
}

.navbar .nav-links .signup-btn:hover {
    background-color: #0856a4;
    transform: scale(1.1);
}

.dark-mode .navbar .nav-links .signup-btn:hover {
    background-color: #208fff;
}

.navbar .nav-links .signup-btn:active {
    transform: scale(.9);
}

.navbar .nav-n {
    color: #0A66C2;
}

.dark-mode .navbar .nav-n {
    color: #3D9EFF;
}

/* Footer styles */
.footer .footer-inner{
    text-align: center;
    padding: 2rem 4rem 2rem;    
    font-size: .8rem;
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.footer .footer-inner .footer-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
}

.footer .footer-links a {
    transition: all .2s ease-in-out;
    min-width: max-content;
}

.footer .footer-links a:hover {
    color: #0A66C2;
}

.dark-mode .footer .footer-links a:hover {
    color: #3D9EFF;
}

/* responsiveness */

@media (max-width: 900px) {

    .navbar-inner {
      padding: 1rem 2rem;
    }

      .footer .footer-inner{
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;
    }
    
}

@media (max-width: 360px) {
    .navbar-inner {
        flex-direction: column;
    }
}