.not-found-container {
    padding: 4rem;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.not-found-container .empty-img {
    max-width: 200px;
}

.not-found-container .not-found-container-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;    
}

.not-found-container .not-found-container-title h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.not-found-container .not-found-container-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#064D94, #0A66C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .not-found-container .not-found-container-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#3D9EFF, #208fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.not-found-container .not-found-container-title p {
    max-width: 600px;
    margin-top: 2rem;
}

@media (max-width: 900px) {
    .not-found-container {
        padding: 2rem;
    }
}

@media (max-width: 358px) {
    .not-found-container .not-found-container-title h1 { 
        font-size: 2.3rem;
    }
}