/* Profile page Styles */
.Profile-page {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.Profile-page .Profile-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;    
}

.Profile-page .Profile-title h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.Profile-page .Profile-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#064D94, #0A66C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .Profile-page .Profile-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#3D9EFF, #208fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Profile-page .signup-page {
    align-items: center;
}

.Profile-page input:disabled {
    cursor: not-allowed;
}

.Profile-page .signup-page .name-container .btn{
    flex: 1;
}

@media (max-width: 900px) {
    .Profile-page {
        padding: 2rem;
        gap: 2rem;
    }

    .Profile-page .Profile-title {
        padding: 0 2rem;
    }
}

@media (max-width: 670px) {
    .Profile-page .Profile-title h1 { 
        font-size: 2.3rem;
    }
}