/* Authentication styles */
.login-page, .signup-page {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.login-page .login-title, .signup-page .signup-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.login-page .login-title h1, .signup-page .signup-title h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.login-page .login-title h1 span, .signup-page .signup-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#064D94, #0A66C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .login-page .login-title h1 span, .dark-mode .signup-page .signup-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#3D9EFF, #208fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-page .login-title .description, .signup-page .signup-title .description {
    max-width: 450px;
}

.login-page .login-form-container, .signup-page .signup-form-container {
    padding: 0 4rem;
    border-radius: 8px;
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-page .login-form, .signup-page .signup-form {
    flex: .85;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
    align-items: center;
    justify-content: center;
}

.login-page .login-form div, .signup-page .signup-form div,
.login-page .login-form button, .signup-page .signup-form button,
.login-page .login-form input, .signup-page .signup-form input, .forgot-password-modal input {
    width: 100%;
}

.login-form .error, .signup-form .error{
    color: rgb(193, 65, 65);
    text-align: start;
}

.login-form input, .signup-form input, .forgot-password-modal input {
    /* background: none;
    width: 100%;
    padding: 1rem 2rem;
    color: #dedfdf;
    font-size: inherit;
    border-radius: 5px;
    border: 1px solid  #dedfdf;
    outline: none;
    transition: all .1s ease-in-out; */
    padding: 1rem;
    border: 1px solid #dedfdf1e;
    border-radius: 5px;
    background-color: #ffffff06;
    transition: all .2s ease-in-out;
    width: 100%;
}

.login-form input:hover, .signup-form input:hover, .forgot-password-modal input:hover {
    border: 1px solid #0A66C2;
}

.login-form input:focus, .signup-form input:focus, .forgot-password-modal input:focus {
    border: 2px solid #0A66C2;
}

.dark-mode .login-form input:hover, .dark-mode .signup-form input:hover, .dark-mode .forgot-password-modal input:hover {
    border: 1px solid #3D9EFF;
}

.dark-mode .login-form input:focus, .dark-mode .signup-form input:focus, .dark-mode .forgot-password-modal input:focus {
    border: 2px solid #3D9EFF;
}

.signup-form .name-container {
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
}

.signup-form .name-container input{
    flex: 1;
    min-width: 120px;
}

.login-form .login-button, .signup-form .signup-btn {
    padding: 1rem 2rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: inherit;
    background-color: #0A66C2;
    color: #1B1F23;
    transition: all .2s ease-in-out;
    font-weight: 500;
}

.dark-mode .login-form .login-button, .dark-mode .signup-form .signup-btn {
    background-color: #208fff;
    color: #1B1F23;
}

.login-form .login-button:hover, .signup-form .signup-btn:hover {
    transform: scale(1.03);
}

.login-form .login-button:active, .signup-form .signup-btn:active {
    transform: scale(.99);    
}

.password-input-container {
  position: relative;
}

.login-page .login-form .password-toggle, .signup-page .signup-form .password-toggle {
  position: absolute;
  right: 1rem;
  top: 54%;
  transform: translateY(-50%);
  font-size: 18px;
  width: max-content;
  cursor: pointer;
}

.password-toggle:hover {
    color: #0A66C2;
}

.dark-mode .password-toggle:hover {
    color: #3D9EFF;
}

.login-form .or, .signup-form .or {
    text-align: center;
    color: #9e9e9e;
}

.inactive-user-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99; /* Ensure it appears above other elements */
}

.inactive-user-msg {
    position: relative;
    width: 90%;
    max-width: 500px;
    background: #1B1F23;
    /* color: #1B1F23; */
    padding: 4rem;
    border-radius: 5px;
    font-weight: 700;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 2rem;
}

.login-page .inactive-user-msg .close-btn, .signup-page .inactive-user-msg .close-btn {
    position: absolute;
    top: .5rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    /* color: #1B1F23; */
    width: max-content;
}

.inactive-user-msg .resend-verification-btn{
    background-color: #0A66C2;
    color: #dedfdf;
    padding: 1rem 2rem;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    margin-top: 2rem;
}

.inactive-user-msg .resend-verification-btn:hover {
    transform: scale(1.05);
}

.inactive-user-msg .resend-verification-btn:active {
    transform: scale(.99);
}

.dark-mode .inactive-user-msg .resend-verification-btn{
    background-color: #3D9EFF;
    color: #1B1F23;
}

.inactive-user-msg .resend-verification-btn:disabled {
    background-color: #dedfdf;
    color: #1B1F23;
    cursor: not-allowed;
}

.login-page .login-form .google-signin-btn, .signup-page .signup-form .google-signin-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #f3f3f3;
}

.login-page .forgot-password-btn {
    text-align: end;
    color: #3D9EFF;
}

.forgot-password-modal, .forgot-password-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

label {
    align-self: flex-start;
}

@media (max-width: 900px) {
    .login-page, .signup-page {
        padding: 1rem 0 0;
        gap: 1rem;
    }

    .login-page .login-title, .signup-page .signup-title{
        padding: 0 2rem;
    }

    .login-page .login-form-container, .signup-page .signup-form-container {
        padding: 2rem;
        border-radius: 0;
        gap: 2rem;
        flex-direction: column;

    }
}

@media (max-width: 670px) {
    .login-page .login-title h1, .signup-page .signup-title h1 { 
        font-size: 2.3rem;
    }
}

@media (max-width: 484px) {

    .login-page .login-title h1, .signup-page .signup-title h1 { 
        font-size: 2rem;
    }
}