/* General styles */
.general-msg-container {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  font-weight: 500;
  background: gray;
  width: 100%;
  color: #dedfdf;
  z-index: 100
}
  
.general-msg-container.success {
    background: #0A66C2;
}

.general-msg-container.success .progress{
    background: #074582;
}

.general-msg-container.error {
    background: rgb(193, 65, 65);
}

.general-msg-container.error .progress {
    background: rgb(141, 33, 33);
}

.general-msg-container .general-msg {
    flex-grow: 1;
    padding-right: 0.5rem;
    font-size: 1rem;
}

.general-msg-container button {
    font-size: 1.5rem;
}

.general-msg-container .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 2px;
}

.general-msg-container .progress {
    height: 100%;
    background: rgb(89, 89, 89);
    border-radius: 2px;
}