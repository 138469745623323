/* Landing page */
.landing-page {
    width: 100%;
    /* display: flex;
    flex-direction: column;
    gap: 4rem; */
}

.landing-page-section {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding: 3rem 4rem 0;
}

.landing-page section{
    padding-bottom: 3rem;
}

section:last-of-type {
    padding-bottom: 1rem;
}

.landing-bg-color {
    background-color: #ffffff0a;
}

.landing-page .hero-section {
    justify-content: space-between;
    display: flex;
    gap: 4rem;
    align-items: center;
    width: 100%;
}

.landing-page .hero-txt {
    /* width: 50%; */
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.landing-page .hero-txt h1{
    font-size: 3.3rem;
    line-height: 3.3rem;
    max-width: 590px;
}

.landing-page .hero-txt p{ 
    max-width: 550px;
}

.landing-page .btn-container{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.landing-page .hero-img {
    width: 50%;
    height: 85vh;
    max-height: 550px;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../assets/landing.webp');
    /* max-width: 800px; */
}

.landing-page .features-section {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.landing-page .features-section .feature-container{
    width: 25%;
}


.landing-page .features-section h4{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.landing-page .features-section .feature-container .icon{
    font-size: 2rem;
}

.landing-page h2 {
    font-size: 2rem;
    /* max-width: 500px; */
}

.landing-page p {
    max-width: 600px;
}

.landing-page .demo-section, .landing-page .last-cta-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    text-align: center;
}

.landing-page .demo-section .demo-vid {
    width: 100%;
    max-width: 900px;
    aspect-ratio: 16/9;
    border: none;
    border-radius: 10px;
}

.landing-page .extension-imgs-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.landing-page .extension-imgs-section img {
    width: 100%;
    max-width: 700px;
}

.landing-page .mid-cta-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    gap: 1rem 4rem;
    flex-wrap: wrap;
}

.landing-page .mid-cta-section .mid-cta-txt {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.landing-page .mid-cta-section .store-img{
    background-image: url('../assets/Google_Chrome_Web_Store_icon_2022.svg');
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.landing-page .faq-section {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (max-width: 900px) {
    .landing-page .hero-section {
      flex-direction: column-reverse;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      margin: 2rem auto;
    }

    .landing-page .hero-section {
        padding: 0;
        margin: 0;
    }

    .hero-section .hero-img {
      width: 100%;
      height: 90vw;
      max-height: 50vh;
      background-position: center;
    }

    .landing-page .hero-txt {
      padding-left: 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    /* .landing-page {
      padding: 2rem;
    } */

    .landing-page .landing-page-section {
        padding: 2rem;
    }

    .landing-page section {
        padding-bottom: 2rem;
    }

    .landing-page section .landing-page-section {
        padding-bottom: 0rem;
    }

    .landing-page section:last-of-type {
        padding-bottom: 0rem;
    }

}

@media (max-width: 670px) {
    .landing-page .hero-txt h1 {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }

    .landing-page h2 {
        font-size: 1.75rem;
    }
    
    .landing-page .mid-cta-section .mid-cta-txt{
        text-align: center;
        align-items: center;
    }

    .landing-page .btn-container{
        flex-direction: column;
    }
}

@media (max-width: 484px) {

    .landing-page .hero-txt h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
}