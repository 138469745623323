/* admin page */

.dashboard-container {
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 0 4rem;
  }
  
  .dashboard-title {
    margin: 2rem 0;
    color: #dedfdf;
  }
  
  .metrics-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .metric-card {
    flex: 1;
    text-align: center;
    padding: 1rem;
    background-color: #0A66C2;
    border-radius: 8px;
  }

  /* .dark-mode .metric-card {
    background-color: #3D9EFF;
  } */
  
  .metric-card h2 {
    margin-bottom: 0.5rem;
    font-size: inherit;
  }
  
  .metric-card p {
    font-size: 1.2rem;
  }
  
  .highlight {
    color: #b5d1ed;
  }
  
  .graphs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;
  }
  
  .chart-section {
    margin-bottom: 2rem;
  }
  
  .chart-section h3 {
    margin-bottom: 1rem;
  }
  
  .chart-select {
    margin-bottom: 1rem;
    border-radius: 4px;
    background-color: #1c1c1c;
    color: #b5d1ed
  }
  
  .engagement-pie {
    height: 300px;
    width: 400px;
    margin: 0 auto;
    color: #1B1F23;
    max-width: 100%;
  }
  
  .user-growth, .notes-created{
      flex: 1;
      max-width: 100%;
  }

@media (max-width: 900px) {
    .dashboard-container {
        padding: 2rem;
        padding-bottom: 0;
    }
}