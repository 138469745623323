/* About page Styles */
.about-page {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.about-page .about-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;    
}

.about-page .about-title h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.about-page .about-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#064D94, #0A66C2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .about-page .about-title h1 span{
    background: linear-gradient(90deg, #dedfdf,#3D9EFF, #208fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-page .about-title p {
    max-width: 450px;
}

.about-page .ship-or-sink {
    background-color: #ffffff0a;
    border-radius: 8px;
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.about-page .ship-or-sink .ship-or-sink-cta{
    border: 4px solid #f3f3f3;
    border-radius: 8px;
    min-width: max-content;
    padding: 2rem 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about-page .ship-or-sink .ship-or-sink-cta h2{
    font-size: 2rem;
}

.about-page .ship-or-sink .ship-or-sink-info{
    max-width: 600px;
    font-size: 1.25rem;
}

.about-page .team-members-container, .about-page .team-members {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.about-page .team-members-container h2{
    text-align: center;
    font-size: 2rem;
}

.about-page .team-member {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    gap: 4rem;
    width: 100%;
    align-items: center;
}

.about-page .team-member:last-of-type {
    flex-direction: row-reverse;
}

.about-page .team-member .member-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    font-size: 1.5rem;
}

.about-page .team-member .member-title .social-icons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.about-page .team-member .member-title .social-icons a {
    transition: all 0.2s ease-in-out;
}

.about-page .team-member .member-title .social-icons a:hover{
    color: #3D9EFF;
    transform: scale(1.2);
}

.about-page .team-member .member-info {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;
}

.about-page .team-member .member-img {
    width: 100%;
    max-width: 400px;
}


@media (max-width: 900px) {
    .about-page {
        padding: 2rem 0;
        gap: 2rem;
    }

    .about-page .about-title, .about-page .team-members-container h2 {
        padding: 0 2rem;
    }

    .about-page .ship-or-sink .ship-or-sink-info{
        text-align: center;
    }

    .about-page .team-members {
        padding: 0 2rem;
    }

    .about-page .team-member, .about-page .team-member:last-of-type{
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: 670px) {
    .about-page .about-title h1 { 
        font-size: 2.3rem;
    }
    
    .about-page .ship-or-sink {
        padding: 2rem;
    }

    .about-page .ship-or-sink .ship-or-sink-info{
        text-align: left;
    }
}

@media (max-width: 484px) {

    .contact-page .contact-title h1 { 
        font-size: 2rem;
    }
}